import React from "react"
import { Box } from "@mui/material"
import PropTypes from "prop-types"

const MainMapLegendsView = (props) => {
  const { url } = props

  if (url.length === 0) {
    return null
  }

  return (
    <Box
      sx={{
        ...props.sx,
        position: "absolute",
        display: "flex",
        flexDirection: "row",
        padding: 0.5,
        backgroundColor: "white",
        gap: "8px", // Adjust the gap value as needed
      }}
    >
      {url.map((imageUrl, index) => (
        <img
          key={index}
          src={imageUrl}
          alt="legends"
          style={{ height: "140px", margin: "0 4px" }} // Add margin property here
        />
      ))}
    </Box>
  )
}

MainMapLegendsView.propTypes = {
  sx: PropTypes.object,
  url: PropTypes.array,
  values: PropTypes.array,
}

export default MainMapLegendsView
