import carto from "@carto/carto.js"
import axios from "axios"
// import PropTypes from "prop-types"
// import { useEffect, useState } from "react"
// import zIndex from "@mui/material/styles/zIndex"

export const cartClient = new carto.Client({
  apiKey: process.env.REACT_APP_CARTO_API_KEY,
  username: process.env.REACT_APP_CARTO_USERNAME,
  serverUrl: process.env.REACT_APP_CARTO_SERVER_URL,
})

export const createSourceByTable = (tablename) => {
  // console.log("table", tablename)
  new carto.source.Dataset(tablename)
}

export const CreateCartoQuerySource = (initialSql) =>
  new carto.source.SQL(initialSql)

export const CreateCartoCss = (initialStyle) =>
  new carto.style.CartoCSS(initialStyle)

export const GetLeafletLayer = (cartoLayer, options = {}) =>
  cartClient.getLeafletLayer({ opacity: 0.3 })

export const CreateCartoLayer = (source, style, options = {}) => {
  let layer = new carto.layer.Layer(source, style, options)
  cartClient.addLayer(layer).then(() => {})
  return layer
}

export const CreateCartoHistogramDataView = (
  source,
  column,
  bins = 5,
  mapBounds = null,
  options = {},
  onChange = () => {}
) => {
  let ds = new carto.dataview.Histogram(source, column, {
    bins: bins,
    ...options,
  })
  if (mapBounds) {
    let bboxFilter = new carto.filter.BoundingBox()
    bboxFilter.setBounds({
      west: mapBounds[1].lng,
      south: mapBounds[1].lat,
      east: mapBounds[0].lng,
      north: mapBounds[0].lat,
    })
    ds.addFilter(bboxFilter)
  }
  ds.on("dataChanged", (data) => {
    onChange(data)
  })
  cartClient.addDataview(ds)
}

export const RemoveCartoLayer = async (cartoLayer) =>
  await cartClient.removeLayer(cartoLayer)

export const RemoveCartoDataView = async (dataview) =>
  await cartClient.removeDataview(dataview)

export const Query1 = async (lat, lng, layerId) => {
  const token = localStorage.getItem("token")
  const url = `${process.env.REACT_APP_BASE_URL}/query?lat=${lat}&layerId=${layerId}&lng=${lng}`

  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return response.data // The response contains fields like 'rows', 'time', 'fields', 'total_rows'
}
