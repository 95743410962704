import React from "react"
import { AppBar, Typography } from "@mui/material"
import PropTypes from "prop-types"

const MainHeaderView = (props) => {
  return (
    <AppBar sx={{ position: "relative", textAlign: "center", padding: 1 }}>
      <Typography variant="h6">LaRC-Flood データ確認用マップ</Typography>
    </AppBar>
  )
}

MainHeaderView.propTypes = {
  sx: PropTypes.object,
}

export default MainHeaderView
