const MainDataState = {
  selectedLayers: [],
}

const infoText = ["再現期間（年)", "上流域の面積（k㎡）", "GCMSの一貫性"]

export default MainDataState

export const layersOrderArray = [
  "cartoLayer4",
  "cartoLayer3",
  "cartoLayer2",
  "cartoLayer1",
  "xyz_layer2",
  "xyz_layer1",
]

export const layerDef = {
  xyz_layer1: {
    // column: "rp10126mid",
    infowindowColumns: ["rp10126mid"],
    tileIndex: "index_rp100_hist",
    popupTitle: "現在気候100年",
    tileColumn: "link",
    tileAttributes: ["value"],
    infoText: ["浸水深（m）"],
    label: "現在気候（再現期間100年）",
    legends: {
      url: "/res/legends/legend-1-02.svg",
      values: [0, 25],
    },
    maxZoom: 12,
    minZoom: 0,
    opacity: 0.5,
    type: "xyz",
    url: "https://larcflood.s3.ap-northeast-1.amazonaws.com/global_hazardmap_RP100_hist_15sec_1980-2014/{z}/{x}/{y}.png",
  },
  xyz_layer2: {
    // column: "rp10126mid",
    infowindowColumns: ["rp10126mid"],
    tileIndex: "index_rp100_ssp585",
    popupTitle: "将来気候2080年（SSP5-8.5、再現期間100年）",
    tileColumn: "link",
    tileAttributes: ["value"],
    infoText: ["浸水深（m）"],
    label: "将来気候2080年（SSP5-8.5、再現期間100年）",
    legends: {
      url: "/res/legends/legend-1-02.svg",
      values: [0, 25],
    },
    maxZoom: 12,
    minZoom: 0,
    opacity: 0.5,
    type: "xyz",
    url: "https://larcflood.s3.ap-northeast-1.amazonaws.com/global_hazardmap_RP100_ssp585_15sec_21thend_2/{z}/{x}/{y}.png",
  },
  cartoLayer1: {
    colorColumn: null,
    query: `SELECT * FROM "${process.env.REACT_APP_CARTO_USERNAME}".uparea_mask where uparea_15k is null`,
    infowindowColumns: [],
    infoText: [],
    label: "流域面積150,000k㎡以上の河川",
    legends: null,
    maxZoom: 12,
    minZoom: 0,
    opacity: 0.8,
    type: "carto",
  },
  cartoLayer2: {
    colorColumn: null,
    query: `SELECT * FROM "${process.env.REACT_APP_CARTO_USERNAME}".uparea_mask where uparea_625k is null`,
    infowindowColumns: [],
    infoText: [],
    label: "流域面積62,500k㎡以上の河川",
    legends: null,
    maxZoom: 12,
    minZoom: 0,
    opacity: 0.8,
    table: "larc_flood2021",
    type: "carto",
  },
  cartoLayer3: {
    colorColumn: null,
    query: `SELECT * FROM "${process.env.REACT_APP_CARTO_USERNAME}".uparea_mask where uparea_10k is null`,
    infowindowColumns: [],
    infoText: [],
    label: "流域面積10,000k㎡以上の河川",
    legends: null,
    maxZoom: 12,
    minZoom: 0,
    opacity: 0.8,
    type: "carto",
  },
  cartoLayer4: {
    colorColumn: "c_585_100",
    query: `SELECT * FROM "${process.env.REACT_APP_CARTO_USERNAME}".larc_flood2021`,
    infowindowColumns: ["ssp585100yr", "uparea396a", "consist245"],
    infoText,
    label: "洪水頻度変化予測（1/100, RCP8.5 based on SSP5）",
    legends: {
      url: "/res/legends/legend-1-01.svg",
      values: [],
    },
    maxZoom: 12,
    minZoom: 0,
    opacity: 0.5,
    type: "carto",
  },
}
