import React, { useState, useEffect } from "react"
import { Box } from "@mui/material"
import LeftPanelView from "./leftPanel"
import MapView from "./map"
import HeaderView from "./header"
import UseMainViewData from "./data"
import AuthenticationModal from "./auth/Dialog"
import jwtDecode from "jwt-decode"

export const MainDataContext = React.createContext()

const MainView = (props) => {
  const [authenticated, setAuthenticated] = useState(false)
  const [map, setMap] = useState()
  const [modalOpen, setModalOpen] = useState(true)

  const useMainData = UseMainViewData()

  useEffect(() => {
    const token = localStorage.getItem("token")

    if (token) {
      try {
        const decodedToken = jwtDecode(token)
        const currentTime = Date.now() / 1000

        if (decodedToken.exp > currentTime) {
          validateToken(token)
        } else {
          localStorage.removeItem("token")
        }
      } catch (error) {
        localStorage.removeItem("token")
      }
    }
  }, [])

  const validateToken = async (token) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/verify-token`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          // body: JSON.stringify({ token }),
        }
      )

      if (response.ok && response.status === 200) {
        handleLoginSuccess(token)
      } else {
        localStorage.removeItem("token")
        alert("Invalid token or token has expired.")
      }
    } catch (error) {
      console.error("Error verifying token:", error)
      alert("An error occurred while verifying the token.")
    }
  }

  const handleLoginSuccess = async (token) => {
    localStorage.setItem("token", token)
    setAuthenticated(true)
    setModalOpen(false)
  }

  const handleLogin = async (password) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ password }),
      })

      const data = await response.json()

      if (response.ok && response.status === 200) {
        handleLoginSuccess(data.token)
      } else {
        alert(data.message)
      }
    } catch (error) {
      console.error("Error logging in:", error)
      alert("An error occurred while logging in.")
    }
  }

  return (
    <MainDataContext.Provider value={useMainData}>
      <Box width="100%" height="100%" display="flex" flexDirection="column">
        <HeaderView sx={{ height: "80px" }} />
        {authenticated ? (
          <Box flexGrow="1" display="flex" flexDirection="row">
            <LeftPanelView
              sx={{
                width: "365px",
              }}
              map={map}
            />
            <MapView
              sx={{
                flexGrow: 1,
              }}
              onInitialized={(m) => setMap(m)}
            />
          </Box>
        ) : (
          <AuthenticationModal
            open={modalOpen}
            onClose={(_, reason) => {
              if (reason !== "backdropClick") {
                setModalOpen(false)
              }
            }}
            onLogin={handleLogin}
          />
        )}
      </Box>
    </MainDataContext.Provider>
  )
}

export default MainView
