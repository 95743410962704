/* eslint-disable no-case-declarations */
import React, { useContext, useEffect, useRef, useState } from "react"
import { Box, Typography } from "@mui/material"
import PropTypes from "prop-types"
import * as L from "leaflet/dist/leaflet"
import "leaflet/dist/leaflet.css"
import {
  cartClient,
  CreateCartoQuerySource,
  CreateCartoCss,
  CreateCartoLayer,
  Query1,
} from "../../../manager/carto"
import { MainDataContext } from "../index"
import { layerDef, layersOrderArray } from "../data/state"
import MainMapLegendsView from "./legends"
import MainMapSearchView from "./search"
// import CartoMapLegendsComponent from "./legends/Cartolegend"

let mapInitializeTimer = null
let mapInitialized = false

const baseLayers = {
  OSMマップ: L.tileLayer(
    "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    {}
  ),
  ダークモード: L.tileLayer(
    "https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png",
    {}
  ),
  "地理院タイル(標準)": L.tileLayer(
    "https://cyberjapandata.gsi.go.jp/xyz/std/{z}/{x}/{y}.png",
    { tileSize: 256, maxNativeZoom: 18, maxZoom: 22 }
  ),
  "地理院タイル(淡色)": L.tileLayer(
    "https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png",
    { tileSize: 256, maxNativeZoom: 18, maxZoom: 22 }
  ),
  "地理院タイル(写真)": L.tileLayer(
    "https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg",
    { tileSize: 256, maxNativeZoom: 18, maxZoom: 22 }
  ),
}

let layers = Object.fromEntries(
  layersOrderArray.map((k, i) => {
    let v = layerDef[k]
    switch (v.type) {
      case "carto":
        // Create Carto query source

        const source = CreateCartoQuerySource(v.query)
        const polyFill = v.colorColumn
          ? `ramp([${v.colorColumn}], (#0d1453, #3055a5, #3691cf, #59c3dc, #b1dee5, #c2c3c3, #f9f388, #fcd200, #eb6301, #ec7396, #e60a13, #ffffff), ("0～5", "5～25", "25～50", "50～75", "75～95", "95～105", "105～125", "125～250", "250～500", "500～1000", ">1000"), "=")`
          : "black"
        const css = `#layer {
          polygon-fill: ${polyFill};
          polygon-opacity: ${v.opacity || "0.8"};
          line-width: 0;
          line-color: #FFF;
          line-opacity: 0.5;
        }`
        // Create Carto CSS
        const style = CreateCartoCss(css)

        // Create Carto layer
        const cartoLayer = CreateCartoLayer(source, style, {
          featureClickColumns: v.infowindowColumns,
          id: k,
          visible: false,
        })
        return [k, { layer: cartoLayer, type: v.type, config: v }]
      case "xyz":
      default:
        return [
          k,
          {
            layer: L.tileLayer(v.url, {
              maxNativeZoom: v.maxZoom,
              minNativeZoom: v.minZoom,
              opacity: v.opacity || 0.5,
              zIndex: (i + 1) * 100,
            }),
            type: v.type,
          },
        ]
    }
  })
)

const blueIcon = L.icon({
  iconUrl:
    "https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
})

const MapView = (props) => {
  const mapRef = useRef()
  const markerRef = useRef()

  const { state } = useContext(MainDataContext)
  const [map, setMap] = useState()
  const [clickMapData, setClickMapData] = useState()
  const [legendsArray, setLegendsArray] = useState([])

  const clearMarker = () => {
    if (markerRef.current) {
      markerRef.current.remove()
      markerRef.current = null
    }
  }

  useEffect(() => {
    if (!mapRef.current || mapInitialized) {
      return
    }

    clearTimeout(mapInitializeTimer)

    mapInitializeTimer = setTimeout(() => {
      let m = L.map(mapRef.current, {
        maxZoom: 18,
      })
      m.setView(
        [
          parseFloat(process.env.REACT_APP_MAP_INITIAL_LATITUDE),
          parseFloat(process.env.REACT_APP_MAP_INITIAL_LONGITUDE),
        ],
        parseInt(process.env.REACT_APP_MAP_INITIAL_ZOOM)
      )
      L.control.layers(baseLayers).addTo(m)

      setMap(m)
    }, 100)

    return () => {
      map?.off()
      map?.remove()
      mapInitialized = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapRef])

  useEffect(() => {
    const selectedTypeUrls = state.selectedLayers
      .map((key) => layerDef[key]?.legends?.url)
      .filter((url) => url !== undefined)

    const uniqueUrls = Array.from(new Set(selectedTypeUrls))

    setLegendsArray(uniqueUrls)
  }, [state.selectedLayers])

  useEffect(() => {
    if (!map) {
      return
    }
    let cartoLeafletLayer = null
    map.addLayer(baseLayers["OSMマップ"])

    if (props.onInitialized) {
      props.onInitialized(map)
    }

    function onFeatureClick(featureEvent, config) {
      let content = '<div class="widget">'
      if (config?.infowindowColumns?.length) {
        config.infowindowColumns.forEach((column, index) => {
          const columnName = config.infoText[index]
          if (featureEvent.data[column]) {
            content += `<div><b>${columnName}: </b> ${featureEvent.data[
              column
            ].toLocaleString()}</div>`
            // Adding .toLocaleString() to format the value with commas
          }
        })
      }
      content += `</div>`
      L.popup().setLatLng(featureEvent.latLng).setContent(content).openOn(map)
    }

    if (layers) {
      let flag = false
      Object.keys(layers).forEach((key) => {
        const layer = layers[key]
        if (layer.type === "carto") {
          flag = true
          layer.layer.on("featureClicked", function (featureEvent) {
            onFeatureClick(featureEvent, layer.config)
          })
        }
      })
      if (flag) {
        cartoLeafletLayer = cartClient.getLeafletLayer()
        cartoLeafletLayer.addTo(map)
      }
    }

    const searchBox = document.getElementById("search-box")
    const clearBox = document.getElementById("clear-button")
    const searchButton = document.getElementById("search-button")

    map.on("click", (e) => {
      if (
        searchBox?.contains(e.originalEvent.target) ||
        clearBox?.contains(e.originalEvent.target) ||
        searchButton?.contains(e.originalEvent.target)
      ) {
        return
      }
      setClickMapData(e)
    })

    map.on("baselayerchange", (layer) => {
      if (cartoLeafletLayer) {
        cartoLeafletLayer.bringToFront()
      }
    })

    // Disable click propagation on the search box and search button
    L.DomEvent.disableClickPropagation(searchBox)
    L.DomEvent.disableClickPropagation(searchButton)

    return () => {
      map?.off()
      map?.remove()
      mapInitialized = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map])

  useEffect(() => {
    if (!map) {
      return
    }

    map.closePopup()

    // Remove existing layers
    Object.values(layers).forEach((l) => {
      switch (l.type) {
        case "carto":
          l.layer.hide()
          break
        case "xyz":
        default:
          map.removeLayer(l.layer)
          break
      }
    })

    // Iterate over layers
    state.selectedLayers.forEach((key) => {
      const layer = layers[key]
      switch (layer.type) {
        case "carto":
          layer.layer.show()
          // layer.layer.bringToFront()
          break
        case "xyz":
        default:
          layer.layer.addTo(map)
          break
      }
    })
  }, [map, state.selectedLayers])

  useEffect(() => {
    const [layerId] = state.selectedLayers
    if (!map || !clickMapData || !["xyz"].includes(layerDef[layerId]?.type)) {
      return
    }
    L.popup()
      .setLatLng(clickMapData.latlng)
      .setContent("<div>読込中...</div>")
      .openOn(map)
    const title = layerDef[layerId]?.popupTitle
    let content = `
      <div class="map_pop_window">
        <div class="title" style="font-weight:bold;text-align: center;">${title}</div>
        <table>
          <tr>
            <th>座標</th>
            <td>${clickMapData.latlng.lat.toFixed(
              5
            )},${clickMapData.latlng.lng.toFixed(5)}</td>
          </tr>`

    try {
      Query1(clickMapData.latlng.lat, clickMapData.latlng.lng, layerId).then(
        (val) => {
          if (val) {
            let trows = []
            Object.keys(layerDef).forEach((k) => {
              if (state.selectedLayers.includes(k)) {
                let v = layerDef[k]
                v.tileAttributes.forEach((column, i) => {
                  trows.push(`
                  <tr>
                    <th>${v.infoText[i]}</th>
                    <td>${val[column]?.toFixed(1) ?? "--"}</td>
                  </tr>
                `)
                })
              }
            })

            content += `${trows.join("\n")}</table></div>`
            L.popup()
              .setLatLng(clickMapData.latlng)
              .setContent(content)
              .openOn(map)
          } else {
            map.closePopup()
          }
        }
      )
    } catch (err) {
      map.closePopup()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clickMapData])

  return (
    <Box sx={props.sx}>
      <Box
        ref={mapRef}
        sx={{
          overflow: "hidden",
          width: "100%",
          height: "100%",
          position: "relative", // Add this line to make position relative
        }}
      >
        <MainMapSearchView
          sx={{
            position: "absolute",
            top: "8px",
            left: "200px",
            width: "50%",
            maxWidth: "450px",
            height: "38px",
            zIndex: 2000,
          }}
          onSearch={(latLng) => {
            clearMarker() // Remove existing marker before placing a new one

            const marker = L.marker(latLng, { icon: blueIcon })
            marker.addTo(map)
            markerRef.current = marker
            map?.flyTo(latLng, 12, {
              animation: true,
              duration: 0.8,
            })
          }}
          clearMarker={clearMarker}
        />
        <MainMapLegendsView
          url={legendsArray}
          sx={{ left: "8px", bottom: "8px", zIndex: 5000 }}
        />
      </Box>

      <Box
        sx={{
          position: "absolute",
          bottom: "16px",
          right: 1,
          backgroundColor: "rgba(255,255,255,.8)",
          zIndex: 3000,
          paddingX: 1,
          paddingY: 0,
        }}
      >
        <Typography fontSize="small">
          ©2023 Pacific Spatial Solutions Inc.
        </Typography>
      </Box>
    </Box>
  )
}

MapView.propTypes = {
  sx: PropTypes.object,
  onInitialized: PropTypes.func,
}

export default MapView
