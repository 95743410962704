import React from "react"
import { Box } from "@mui/material"
import MainView from "../main"

const RootView = (props) => {
  return (
    <Box margin={0} padding={0} width="100vw" height="100vh">
      <MainView />
    </Box>
  )
}

export default RootView
