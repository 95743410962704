import React, { useMemo, useState } from "react"
import { Box, Button, TextField, InputAdornment } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import PropTypes from "prop-types"
import * as L from "leaflet/dist/leaflet"

const MainMapSearchView = (props) => {
  const [value, setValue] = useState("")
  const [showClearButton, setShowClearButton] = useState(false) // New state variable

  const latLng = useMemo(() => {
    if (!value || value.length === 0) {
      return []
    }
    let m = value.trim().match(/^((?:-|)[0-9\\.]+)\s*,\s*((?:-|)[0-9\\.]+)$/)
    if (!m) {
      return []
    }
    let f1 = parseFloat(m[1])
    let f2 = parseFloat(m[2])
    if (!f1 || !f2) {
      return []
    }
    return [f1, f2]
  }, [value])

  const disableClearButtonPropagation = () => {
    setTimeout(() => {
      const list = document.getElementsByName("clear-button")
      if (list && list[0]) {
        L.DomEvent.disableClickPropagation(list[0])
      }
    }, 100)
  }

  const handleSearchButtonClick = (e) => {
    latLng && latLng.length && props.onSearch && props.onSearch(latLng)
    setShowClearButton(true) // Reset showClearButton state
    disableClearButtonPropagation()
  }

  const handleClearButtonClick = () => {
    setValue("")
    setShowClearButton(false)

    if (props.clearMarker) {
      props.clearMarker()
    }
  }

  return (
    <Box
      sx={{
        ...props.sx,
        display: "flex",
        flexDirection: "row",
        gap: 1,
      }}
    >
      <TextField
        id="search-box"
        value={value}
        onChange={(e) => {
          setValue(e.target.value)
          setShowClearButton(e.target.value.length > 0) // Show the 'x' button when there is input
          disableClearButtonPropagation()
        }}
        label="緯度、経度"
        size="small"
        sx={{
          backgroundColor: "white",
          flexGrow: 1,
        }}
        InputProps={{
          endAdornment: showClearButton && (
            <InputAdornment position="end">
              <Button
                id="clear-button"
                name="clear-button"
                onClick={handleClearButtonClick}
                size="small"
              >
                <CloseIcon />
              </Button>
            </InputAdornment>
          ),
        }}
      />
      <Button
        id="search-button"
        onClick={handleSearchButtonClick}
        variant="contained"
        sx={{ width: "100px" }}
      >
        移動
      </Button>
    </Box>
  )
}

MainMapSearchView.propTypes = {
  sx: PropTypes.object,
  onSearch: PropTypes.func,
  clearMarker: PropTypes.func,
}

export default MainMapSearchView
