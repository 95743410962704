import PropTypes from "prop-types"
import React, { useState } from "react"
import { Box, Modal, TextField, Button } from "@mui/material"

const AuthenticationModal = ({ open, onClose, onLogin }) => {
  const [password, setPassword] = useState("")

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      onClose(event, reason)
    }
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value)
  }

  const handleLogin = () => {
    onLogin(password)
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          minWidth: "300px",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            padding: "8px",
            fontSize: "14px",
            color: "black",
            fontWeight: "bold",
          }}
        >
          パスワードを入力してください
        </Box>

        <TextField
          type="password"
          value={password}
          onChange={handlePasswordChange}
          fullWidth
          sx={{ mt: 2 }}
        />
        <Button variant="contained" onClick={handleLogin} sx={{ mt: 2 }}>
          ログイン
        </Button>
      </Box>
    </Modal>
  )
}

AuthenticationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onLogin: PropTypes.func.isRequired,
}

export default AuthenticationModal
