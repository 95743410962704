import { useReducer } from "react"

import initialState from "./state"
import reducer, { ActionType } from "./reducer"

const UseMainViewData = () => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
  })

  const addSelectedLayer = (value) => {
    if (state.selectedLayers.includes(value)) {
      return
    }
    dispatch({
      type: ActionType.SetSelectedLayers,
      value: [...state.selectedLayers, value],
    })
  }

  const removeSelectedLayer = (value) => {
    if (!state.selectedLayers.includes(value)) {
      return
    }
    dispatch({
      type: ActionType.SetSelectedLayers,
      value: state.selectedLayers.filter((k) => k !== value),
    })
  }

  const toggleSelectedLayer = (value) => {
    if (state.selectedLayers.includes(value)) {
      // Remove the selected layer
      removeSelectedLayer(value)
    } else {
      // Add the selected layer
      addSelectedLayer(value)
    }
  }

  const setSelectedLayers = (value) => {
    dispatch({ type: ActionType.SetSelectedLayers, value: value })
  }

  return {
    state,
    addSelectedLayer,
    removeSelectedLayer,
    toggleSelectedLayer,
    setSelectedLayers,
  }
}

export default UseMainViewData
