import React, { useContext } from "react"
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Checkbox, // Changed import
} from "@mui/material"
import PropTypes from "prop-types"
import { layerDef } from "../data/state"
import { MainDataContext } from "../index"

const LeftPanelView = (props, map) => {
  const { state, setSelectedLayers } = useContext(MainDataContext)

  const handleToggleLayer = (value) => {
    if (state.selectedLayers.includes(value)) {
      setSelectedLayers(state.selectedLayers.filter((layer) => layer !== value))
    } else {
      setSelectedLayers([...state.selectedLayers, value])
    }
  }

  return (
    <Box backgroundColor="inherit" sx={props.sx} borderRight="1px solid #999">
      <List sx={{ width: "100%", maxWidth: 365, bgColor: "background.paper" }}>
        {Object.keys(layerDef).map((k) => {
          const labelId = `checkbox-list-label-${k}`

          return (
            <ListItem key={k} sx={{ m: 0, p: 0, paddingLeft: 1 }}>
              <ListItemButton
                sx={{
                  padding: "0!important",
                  minWidth: "0!important",
                }}
                role={undefined}
                onClick={() => handleToggleLayer(k)}
                disabled={!map}
              >
                <ListItemIcon sx={{ minWidth: "0" }}>
                  <Checkbox // Changed component
                    checked={state.selectedLayers.includes(k)}
                    // onChange={() => toggleSelectedLayer(k)}
                  />
                </ListItemIcon>
                <ListItemText
                  id={labelId}
                  primary={layerDef[k].label}
                  primaryTypographyProps={{ fontSize: "small" }}
                />
              </ListItemButton>
            </ListItem>
          )
        })}
      </List>
    </Box>
  )
}

LeftPanelView.propTypes = {
  sx: PropTypes.object,
}

export default LeftPanelView
