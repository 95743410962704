import React, { useMemo } from "react"
// import logo from "./logo.svg"
import "./App.css"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import RootView from "./views/root"

const App = () => {
  const router = useMemo(
    () =>
      createBrowserRouter([
        {
          path: "/",
          element: <RootView />,
        },
      ]),
    []
  )

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          primary: {
            main: "#3c6968",
          },
        },
      }),
    []
  )

  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  )
}

export default App
