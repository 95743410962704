export const ActionType = {
  SetSelectedLayers: "SET_SELECTED_LAYERS",
}

const MainViewDataReducer = (state, action) => {
  switch (action.type) {
    case ActionType.SetSelectedLayers:
      return { ...state, selectedLayers: [...action.value] }
    default:
  }

  return state
}

export default MainViewDataReducer
